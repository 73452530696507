<template>
    <div class="specialTopic">
        <HeadBar titleName="专题列表"></HeadBar>
        <div class="content oepnX">
            <SpecialTopicList></SpecialTopicList>
        </div>
    </div>
</template>

<script>

import SpecialTopicList from "@/views/specialTopic/SpecialTopicList";
import HeadBar from "@/components/HeadBar";
export default {
  name: "SpecialTopic",
    components:{
        HeadBar,SpecialTopicList
    }
}
</script>

<style lang="scss" scoped>
    .specialTopic{
     background: #F5F5F5;
        .content{
            height: calc(100vh - 44px);
            padding: 0 12px;
        }
    }
</style>
