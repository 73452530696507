<template>
    <div class="specialTopicList">
        <PullRefresh v-if="!isLoading" :loading="loading" :refreshing="refreshing" :finished="finished" @onLoad="onLoad"
                     @onRefresh="onRefresh" :isNoData="isNoData" :error="error" :is-higeht-max="true"
                     :adv-list="advList" :hasAdvPagination="true" className="Welfare">
            <div>
                <div class="contentItem" v-for="(item,index) in specialTopicList" :key="index" @click="openDetails(item)">
                    <div class="specialTitle">
                        <span>{{item.name}}</span>
                    </div>
                    <div class="specialImg">
                        <ImgDecypt v-if="item.img" :src="item.img" />
                    </div>
                </div>
            </div>
        </PullRefresh>
        <Loading v-else />
    </div>
</template>

<script>

import PullRefresh from "@/components/PullRefresh";
import Loading from "@/components/Loading";
import ImgDecypt from "@/components/ImgDecypt";
import { queryTopicList } from "@/api/video"

export default {
  name: "SpecialTopicList",
    components:{
        PullRefresh,
        Loading,
        ImgDecypt
    },
    data(){
        return {
            isLoading:true,
            loading: true,
            refreshing: false,
            finished: false,
            isNoData: false,
            error: false,
            advList: [],
            pageNumber: 1,
            pageSize: 10,
            specialTopicList:[]
        }
    },created() {
        this.getopicList();
    },mounted() {
        // <VerticleThree v-if="item['direction'] == 0 || item['direction'] == 1" :videoInfoData="item"></VerticleThree>
        // <VerticleTwo v-if="item['direction'] == 2" :videoInfoData="item"></VerticleTwo>
        // <TransverselyTwo v-if="item['direction'] == 3" :videoInfoData="item"></TransverselyTwo>
        // <TransverselyFive v-if="item['direction'] == 4" :videoInfoData="item"></TransverselyFive>
    },
    methods: {
        async getopicList(type) {
            let req = {
                pageNumber: this.pageNumber,
                pageSize: this.pageSize,
                mustInList: true
            }
            let res = await this.$Api(queryTopicList, req);

            this.loading = false;
            this.refreshing = false;
            this.isLoading = false;

            try {
                if (res && res.code == 200) {

                    if (type == "refresh") {
                        this.specialTopicList = res.data.list;
                    } else {
                        this.specialTopicList = this.specialTopicList.concat(res.data.list);
                    }

                    if (this.pageNumber === 1 && this.specialTopicList.length === 0) {
                        this.isNoData = true;
                    }

                    if (!res.data.hasNext || res.data.list.length < req.pageSize) {
                        this.finished = true;
                    }
                } else {
                    this.error = true;
                }
            } catch (error) {
                this.error = true;
                this.loading = false;
            }
        }, onLoad() {
            this.loading = true;
            if (this.error) {
                this.error = false;
            } else {
                this.pageNumber++
            }
            this.getopicList();
        }, onRefresh() {
            this.pageNumber = 1;
            this.refreshing = true;
            this.finished = false;
            this.isNoData = false;
            this.getopicList('refresh');
        }, openDetails(item) {
            let format = 1;
            switch (item.direction){
                case 0:
                case 1:
                case 2:
                    format = 0;
                    break;
            }
            this.$router.push({
                path:"topicPage",
                query:{
                    topicId: item.id,
                    name: item.name,
                    format: format
                }
            });
        }
    }
}
</script>

<style lang="scss" scoped>
    .specialTopicList{
        height: 100%;
        .contentItem{
         padding: 10px;
            background: #FFFFFF;
            border-radius: 6px;
            margin-top: 12px;

            // /deep/ .van-image{
            //     border-radius: 6px;
            // }

            // /deep/ .van-image__img{
            //     border-radius: 6px;
            // }

            .specialTitle{
                font-size: 15px;
                color: #000000;
                font-weight: bold;
            }

            .specialImg{
                background: rgba(27,22,76,0.06);
                height: 132px;
                /deep/ img {
                 border-radius: 8px;
                }
            }
        }
    }
</style>
